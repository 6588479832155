
import BasicFormGroup from './BasicFormGroup'
import ValidationFeedback from './ValidationFeedback'
export default {
  inject: ['$validator'],
  components: {
    BasicFormGroup,
    ValidationFeedback
  },
  inheritAttrs: false,
  props: ['value', 'inputPreventDefault', 'customErrorMessage'],
  data: () => ({
    isFocused: false,
    elementId: getNextFormElementId()
  }),
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        focus: (event) => {
          this.isFocused = true
          this.$emit('focus', event)
        },
        blur: (event) => {
          this.isFocused = false
          this.$emit('blur', event)
        },
        // This ensures that the component works with v-model
        input: (event) => {
          if (this.inputPreventDefault) {
            event.preventDefault()
          }
          if (typeof event === 'boolean') {
            this.$emit('input', event)
          } else {
            if (typeof event === 'string' || typeof event === 'number' || event instanceof Date) {
              this.$emit('input', event)
            } else {
              // if (event === null) this.$emit('input', null)
              // else this.$emit('input', event.target.value)
            }
          }
        }
      }
    },
    labelIsUp () {
      return this.isFocused || (this.value !== undefined && this.value !== null && this.value !== '')
    },
    getValidation () {
      const validation = {
        required: this.$attrs.required !== undefined && this.$attrs.required !== false,
        ...this.$attrs.validation
      }
      return validation
    },
    name () {
      return this.$attrs.name || this.$attrs.label
    },
    id () {
      return this.$attrs.id || ('formElement' + this.elementId)
    },
    veeField () {
      return this.id + '-' + this.name
    },
    model: {
      get () {
        return this.value
      },
      set (val) {
        if (val !== this.value) {
          this.$emit('input', val)
        }
      }
    }
  }
}

let formElementIdCounter = 0 // is static; distributes unique ids

function getNextFormElementId () {
  // this distributes ids so that it doesn't has to be set by the developer
  const nextId = formElementIdCounter++
  if (formElementIdCounter > 10000) {
    // to prevent possible memoryleak (although unlikely) reset counter
    formElementIdCounter = 0
  }
  return nextId
}
