<template>
  <Modal
    v-if="show"
    size="md"
    title="Garantiebedingung speichern"
    :closable="false"
    @close="$emit('close')"
  >
    <template #body>
      <CaAlert
        v-if="showFormInvalidHint"
        variant="danger"
        class="mb-2"
        message="Es sind noch nicht alle erforderlichen Felder ausgefüllt"
        @close="showFormInvalidHint = null"
      />
      <BasicInput
        v-model="agb.name"
        :columns="{label: ['col-12', 'col-md-5'], input:['col-12', 'col-md-7']}"
        :horizontal="true"
        label="Name"
        placeholder="Dateiname"
        required
      />

      <BasicSelect
        v-model="agb.type"
        :columns="{label: ['col-12', 'col-md-5'], input:['col-12', 'col-md-7']}"
        :horizontal="true"
        label="Typ"
        placeholder="Auswählen"
        :options="[
          {label: 'Garantie', value: 'GARANTIE'},
          {label: 'Kaufschutz', value: 'KAUFSCHUTZ'}
        ]"
        required
      />

      <BasicSelect
        v-model="agb.status"
        :columns="{label: ['col-12', 'col-md-5'], input:['col-12', 'col-md-7']}"
        :horizontal="true"
        label="Status"
        placeholder="Auswählen"
        :options="humanStatusOptions"
      />
      <transition name="slide-fade">
        <BasicDatepicker
          v-if="agbStatus && agbStatus[agb.status].api === 'PUBLISHED'"
          id="publishDate"
          v-model="log.publishDate"
          :columns="{label: ['col-12', 'col-md-5'], input:['col-12', 'col-md-7']}"
          placeholder="TT.MM.JJJJ"
          :horizontal="true"
          label="Gilt ab"
          :required="agbStatus && agbStatus[agb.status].api === 'PUBLISHED'"
        />
      </transition>
      <BasicInput
        v-model="log.text"
        :columns="{label: ['col-12', 'col-md-5'], input:['col-12', 'col-md-7']}"
        :horizontal="true"
        label="Kommentar zur Änderung"
        placeholder="Kommentar"
        required
      />
      <p class="m-0 text-gray">
        Die Änderungen an diesem Dokument werden zusammen mit dem Kommentar im Versionsverlauf eingetragen.
      </p>
      <hr>
      <div class="form-row py-3">
        <div class="col-12 col-md-5">
          Benachrichtigung an Händler
        </div>
        <div class="col-12 col-md-7 d-flex align-items-center">
          <BasicCheckbox
            v-model="log.notification"
            :horizontal="true"
            :margin="false"
          />
        </div>
      </div>
      <p class="m-0 text-gray">
        Bei Benachrichtigung müssen Händler den Änderungen zustimmen.
      </p>
    </template>
    <template #footer>
      <button
        class="btn btn-primary float-right ml-2"
        @click="savePending = true"
      >
        Speichern <i
          v-if="savePending"
          class="fas fa-circle-notch fa-spin"
        />
      </button>
      <button
        class="btn hover-gray-2 text-primary float-right"
        @click="$emit('close')"
      >
        Abbrechen
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker.vue'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox.vue'
import BasicSelect from '@/components/BaseComponents/BasicSelect.vue'
import agbStatus from '@/resources/enums/agbStatus.js'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    Modal,
    BasicInput,
    BasicDatepicker,
    BasicCheckbox,
    BasicSelect
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    agb: {
      type: Object,
      required: true
    },
    log: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      savePending: false,
      showFormInvalidHint: false,
      agbStatus
    }
  },
  computed: {
    humanStatusOptions () {
      return Object.values(this.agbStatus).map(status => ({ label: status.human, value: status.api }))
    }
  },
  watch: {
    savePending: {
      async handler () {
        const valid = await this.$validator.validateAll()
        try {
          if (!valid) {
            this.showFormInvalidHint = true
            return
          }
          this.$emit('save')
        } catch (e) {
          console.error(e)
        } finally {
          this.savePending = false
        }
      }
    }
  }
}
</script>
