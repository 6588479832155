<template>
  <Modal
    v-if="show"
    size="lg"
    title="Neuen Baustein anlegen"
    :closable="false"
    @close="$emit('close')"
  >
    <template #body>
      <CaAlert
        v-if="showFormInvalidHint"
        variant="danger"
        class="mb-2"
        message="Es sind noch nicht alle erforderlichen Felder ausgefüllt"
        @close="showFormInvalidHint = null"
      />
      <BasicInput
        v-model="agbModule.name"
        label="Name des Bausteins"
        :columns="{label: ['col'], input:['col-12']}"
        placeholder="Name"
        :horizontal="true"
        :margin="false"
        class="mb-3"
        required
      />
      <label class="mb-0">Titel</label>
      <div class="form-row mb-3">
        <div class="col-12 col-md-3">
          <BasicInput
            v-model="agbModule.symbol"
            label="Symbol"
            :columns="{label: ['d-none'], input:['col-12']}"
            placeholder="Symbol (z.B §1; a; 1)"
            :horizontal="true"
            :margin="false"
          />
        </div>
        <div class="col-12 col-md-9">
          <BasicInput
            v-model="agbModule.title"
            label="Überschrift des Bausteins"
            :columns="{label: ['d-none'], input:['col-12']}"
            placeholder="Überschrift des Bausteins"
            :horizontal="true"
            :margin="false"
            required
          />
        </div>
      </div>

      <label class="mb-0">Inhalt</label>
      <BasicWysiwyg
        v-model="agbModule.text"
        :file-upload="false"
        :large="true"
        required
      />
    </template>
    <template
      #footer
    >
      <button
        class="btn btn-primary float-right ml-2"
        @click="save()"
      >
        <i
          v-if="savePending"
          class="far fa-spin fa-circle-notch"
        /> Speichern
      </button>
      <button
        class="btn hover-gray-2 text-primary float-right"
        @click="$emit('close')"
      >
        Abbrechen
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import BasicWysiwyg from '@/components/BaseComponents/BasicWysiwyg.vue'
import CaAlert from '@/components/Alert.vue'
import moment from 'moment'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    Modal,
    BasicInput,
    BasicWysiwyg,
    CaAlert
  },
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      agbModule: null,
      loading: false,
      onlyThisDoc: false,
      savePending: false,
      showFormInvalidHint: false
    }
  },
  watch: {
    show: {
      immediate: true,
      handler () {
        this.agbModule = new this.$FeathersVuex.api.AgbModule()
        this.showFormInvalidHint = false
      }
    }
  },
  methods: {
    async save () {
      const valid = await this.$validator.validateAll() && this.agbModule.text
      try {
        if (this.savePending) {
          return
        } else if (!valid) {
          this.showFormInvalidHint = true
          return
        }
        this.savePending = true
        this.loading = true
        if (this.onlyThisDoc) {
          // TODO: Document ID
          this.agbModule.documentId = 'Test'
        }
        const savedModule = await this.agbModule.save()
        const agbModuleLog = new this.$FeathersVuex.api.AgbModuleLog()
        agbModuleLog.text = 'Created Module'
        agbModuleLog.moduleId = savedModule._id
        agbModuleLog.userId = this.$store.getters['auth/user'].user._id
        agbModuleLog.dateTime = moment()
        agbModuleLog.save()
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
        if (valid) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>
