<template>
  <div class="bg-white sticky-top site-header ">
    <div class="shadow-sm py-3 px-4">
      <div
        class="justify-content-between"
        :class="{'row': !columnLayout, 'form-row': columnLayout}"
      >
        <div
          class="col-12"
          :class="{'col-xl-8 col-xxl-6': !columnLayout, 'col-lg': columnLayout}"
        >
          <!-- Loading State -->
          <slot
            v-if="loading"
            name="customLoading"
          >
            <ca-alert
              type="info"
              spin-icon
              rounded
              icon="circle-notch"
              message="Lade Daten..."
            />
          </slot>
          <div
            v-else
            class="form-row"
          >
            <!-- Überschrift (h3) -->
            <div class="col d-flex align-items-center">
              <slot
                v-if="!loading"
                name="heading"
              >
                <h3 class="mb-0">
                  {{ heading }}
                </h3>
                <span
                  v-if="tag"
                  class="badge badge-primary ml-2"
                >{{ tag }}</span>
              </slot>
            </div>
            <div class="col-auto">
              <!-- Buttons etc. -->
              <slot name="buttonSpace" />
            </div>
          </div>
        </div>
        <div class="col-auto">
          <template v-if="showColumnLayout">
            <button
              class="btn px-2"
              :class="{ 'btn-primary': columnLayout, 'btn-secondary text-primary': !columnLayout}"
              @click="$emit('columnLayout', !columnLayout)"
            >
              <i class="fas fa-columns" />
            </button>
          </template>
          <template v-if="!noUser">
            <b-dropdown
              right
              variant="link"
            >
              <template slot="button-content">
                <span class="mr-2">{{ username }}</span>
                <i class="far fa-user" />
              </template>
              <b-dropdown-item :to="{ name: 'UserEdit', params: { userId: me._id } }">
                <span class="text-primary"><i class="far fa-address-card" /> Meine Daten</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item href="#">
            <span class="text-primary"><i class="far fa-credit-card" /> Meine Abonnements</span>
          </b-dropdown-item> -->
              <b-dropdown-item @click="logout()">
                <span class="text-primary"><i class="far fa-power-off" /> Ausloggen</span>
              </b-dropdown-item>
            </b-dropdown>
            <!-- <i class="far fa-comment-lines" /> -->
          </template>
        </div>
      </div>
      <div class="mt-2">
        <slot name="bottom" />
      </div>
    </div>
    <!-- Alerts -->
    <slot name="alert" />
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    loading: {
      default: false,
      type: Boolean
    },
    columnLayout: {
      default: false,
      type: Boolean
    },
    showColumnLayout: {
      default: false,
      type: Boolean
    },
    heading: {
      default: '',
      type: String
    },
    tag: {
      default: undefined,
      type: String
    },
    noUser: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    username () {
      let username
      if (!this.me) return
      if (this.me.firstname) {
        username = this.me.firstname + ' ' + this.me.lastname
      } else {
        username = this.me.lastname
      }
      return username
    },
    me () {
      return this.$store.getters['auth/user'].user
    }
  },
  methods: {
    async logout () {
      await this.$store.dispatch('auth/logout')
      await this.$router.push({ name: 'Login' })
    }
  }
}
</script>
