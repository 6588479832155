<template>
  <div
    class="form-group"
    :class="{'my-3': margin, 'my-0': !margin}"
  >
    <template v-if="horizontal">
      <div
        class="form-row"
        :class="{'align-items-center': alignLabelCenter}"
      >
        <div
          :class="labelColumns"
        >
          <label
            v-if="label || $scopedSlots.label"
            :for="id"
            :class="{'up': labelIsUp}"
            class="mb-0"
          >
            <slot name="label">{{ label }}</slot>
            <span
              v-if="required"
              class="required"
            >*</span>
            <span v-if="infotext || $scopedSlots.slotinfotext">
              <i
                :id="id"
                class="far fa-info-circle text-gray-4 ml-2"
              />
              <b-popover
                :target="id"
                placement="top"
                triggers="hover focus"
                :content="infotext"
              >
                <slot name="slotinfotext" />
              </b-popover>
            </span>
          </label>
        </div>
        <div :class="inputColumns">
          <slot name="input" />
          <slot name="small">
            <small
              v-if="small"
              class="text-muted"
            >{{ small }}</small>
          </slot>
        </div>
      </div>
    </template>
    <template v-else>
      <label
        v-if="label || $scopedSlots.label"
        :for="id"
        :class="{'up': labelIsUp}"
        class="mb-0"
      >
        <slot name="label">{{ label }}</slot>
        <span
          v-if="required"
          class="required"
        >*</span>
        <span v-if="infotext || $scopedSlots.slotinfotext">
          <i
            :id="id"
            class="far fa-info-circle text-gray-4 ml-2"
          />
          <b-popover
            :target="id"
            placement="top"
            triggers="hover focus"
            :content="infotext"
          >
            <slot name="slotinfotext" />
          </b-popover>
        </span>
      </label>
      <slot name="input" />
      <slot name="small">
        <small
          v-if="small"
          class="text-muted"
        >{{ small }}</small>
      </slot>
    </template>
  </div>
</template>
<script>

export default {
  inject: ['$validator'],
  name: 'BasicFormGroup',
  props: {
    required: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    infotext: {
      type: String,
      default: ''
    },
    small: {
      type: String,
      default: ''
    },
    labelIsUp: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Object,
      default: () => {}
    },
    margin: {
      type: Boolean,
      default: true
    },
    alignLabelCenter: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    labelColumns () {
      if (this.columns && this.columns.label) {
        return [this.columns.label]
      } else {
        return ['col-12 col-md-6']
      }
    },
    inputColumns () {
      if (this.columns && this.columns.input) {
        return [this.columns.input]
      } else {
        return ['col-12 col-md-6']
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  span.required {
    color: #9e0000;
  }
</style>
