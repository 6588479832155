<template>
  <div class="full-height d-flex flex-column">
    <AddTextModuleModal
      :show="showAddModal"
      @close="showAddModal = false"
    />
    <EditTextModuleModal
      :show="showEditModal"
      :edit-id="editId"
      @close="showEditModal = false"
    />
    <SaveAgbFromEditor
      v-if="agb && log"
      :show="showSaveModal"
      :agb="agb"
      :log="log"
      @save="save()"
      @close="showSaveModal = false"
    />
    <ca-header
      :heading="agb ? agb.name || 'Neue Garantiebedingung' : 'Laden...'"
      :loading="loading"
      :column-layout="true"
    >
      <template #buttonSpace>
        <button
          class="btn btn-success text-white float-right"
          type="button"
          @click="showSaveModal = true"
        >
          Speichern
        </button>
      </template>
    </ca-header>
    <div
      v-if="!loading"
      class="row flex-row-reverse w-100 h-100 pl-4 space-header"
    >
      <div class="col-3 h-100">
        <ca-card class="h-100">
          <div class="card-header custom-header">
            <div class="container-fluid row align-items-center m-0 p-0">
              <h4 class="m-0">
                Optionen
              </h4>
            </div>
          </div>
          <div class="card-body overflow-hidden pt-0">
            <BasicInput
              v-model="agb.name"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              :horizontal="true"
              label="Name"
              placeholder="Dateiname"
            />

            <BasicSelect
              v-model="agb.type"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              :horizontal="true"
              label="Typ"
              placeholder="Auswählen"
              :options="[
                {label: 'Kaufschutz', value: 'KAUFSCHUTZ'},
                {label: 'Garantie', value: 'GARANTIE'}
              ]"
            />

            <BasicSelect
              v-model="agb.status"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              :horizontal="true"
              label="Status"
              placeholder="Auswählen"
              :options="humanStatusOptions"
            />

            <div
              class="form-row mb-3"
            >
              <div class="col-12 col-md-4 d-flex align-items-center">
                PDF einfügen
              </div>
              <div class="col-12 col-md-8 d-flex align-items-center">
                <div class="btn-group w-100">
                  <label
                    class="btn btn-primary"
                    title="PDF am Ende des Dokumentes einfügen"
                  >
                    <span v-if="!uploadPending">
                      <i class="far fa-file-pdf mr-2" />Datei auswählen
                    </span>
                    <span v-else>
                      <i class="fas fa-circle-notch fa-spin mr-2" />Wird hochgeladen...
                    </span>
                    <input
                      type="file"
                      accept=".pdf"
                      style="display: none;"
                      @change="addPdfFile($event)"
                    >
                  </label>
                </div>
              </div>
            </div>

            <div
              v-for="(attachment, index) of attachments"
              :key="attachment"
              class="w-100 d-flex justify-content-between align-items-center py-2"
            >
              {{ attachment.name }}
              <button
                class="btn bg-transparent text-danger p-1"
                @click="removePdfFile(index)"
              >
                <i class="far fa-times fa-fw" />
              </button>
            </div>

            <div class="row card-header border-top bg-white">
              <h5 class="m-0">
                Versionsverlauf
              </h5>
            </div>
            <div
              v-if="logList && logList.length > 0"
              class="row log-list"
            >
              <div
                v-for="(item, index) in logList"
                :key="index"
                class="d-flex list-item border-bottom py-2 w-100"
              >
                <div
                  class="col-12 d-flex flex-column justify-content-center align-items-start"
                >
                  <small><b>{{ formatDate(item.dateTime, 'DD.MMM YYYY HH:mm') }} Uhr - {{ `${item.user.firstname} ${item.user.lastname}` }}</b></small>
                  {{ item.text }}
                </div>
              </div>
              <div
                v-if="logList.length >= 10"
                class="d-flex justify-content-center w-100 py-2"
              >
                <button
                  class="btn bg-transparent text-primary"
                  @click="loadMoreLogs"
                >
                  Mehr anzeigen...
                </button>
              </div>
            </div>
            <div
              v-else
              class="d-flex justify-content-center py-3 w-100"
            >
              <small>Keine Einträge vorhanden</small>
            </div>
          </div>
        </ca-card>
      </div>
      <div
        class="col-6 h-100"
        :class="{ 'col-9': legacyMode || previewMode}"
      >
        <b-card
          no-body
          class="h-100"
        >
          <b-tabs
            v-model="tabIndex"
            pills
            card
            class="standalone h-100"
          >
            <b-tab
              v-if="!legacyMode"
              title="Felder im Dokument"
              active
              no-body
            >
              <div class="card-body overflow-y-scroll">
                <div
                  v-if="agb.doc.modules && agb.doc.modules.length > 0"
                  class="row"
                >
                  <div
                    v-for="(item, index) in agb.doc.modules"
                    :key="index"
                    class="col-12 mb-4 w-100"
                  >
                    <TextModule
                      :name="item.name"
                      :title="item.symbol ? item.symbol + ' ' + item.title : item.title"
                      :text="item.text"
                      :short-text="false"
                      :disable-hover="true"
                      @up="moveModule(index, index - 1)"
                      @down="moveModule(index, index + 2)"
                      @delete="deleteModule(index)"
                    />
                  </div>
                </div>
                <div
                  v-else
                  class="d-flex flex-column justify-content-center align-items-center h-100"
                >
                  <i class="fad fa-mouse-pointer fa-4x mb-3 text-primary" />
                  <h5 class="m-0">
                    Bausteine links auswählen, um sie dem Dokument hinzuzufügen
                  </h5>
                </div>
              </div>
            </b-tab>
            <b-tab
              no-body
              title="Vorschau"
            >
              <template
                #title
                @click="previewMode = !previewMode"
              >
                Vorschau
              </template>
              <div v-if="!previewLoading">
                <PdfPreview
                  v-if="previewData || legacyMode"
                  :data="previewData"
                />
                <div
                  v-else
                  class="d-flex flex-column justify-content-center align-items-center"
                >
                  <i class="fad fa-file-exclamation fa-4x mb-3 text-primary" />
                  <h5 class="m-0">
                    Keine Bausteine im Dokument vorhanden
                  </h5>
                </div>
              </div>
              <div
                v-else
                class="d-flex justify-content-center align-items-center"
              >
                <i class="fad fa-spinner-third fa-spin fa-3x text-primary mr-3" />
                <h5 class="m-0">
                  Lade Vorschau...
                </h5>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
      <div
        v-if="!legacyMode && !previewMode"
        class="col-3 h-100"
      >
        <ca-card
          class="h-100"
        >
          <div class="card-header custom-header">
            <div class="container-fluid row align-items-center m-0 p-0">
              <h4 class="m-0">
                Bausteine
              </h4>
              <button
                class="btn btn-primary px-2 ml-auto"
                @click="showAddModal = true"
              >
                <i class="fas fa-fw fa-plus" />
              </button>
            </div>
          </div>
          <div class="card-header bg-white">
            <BasicInput
              v-model="search"
              :columns="{label: ['col'], input: ['col-12']}"
              :horizontal="true"
              :margin="false"
              placeholder="Suche"
            >
              <template #append>
                <button
                  class="btn btn-white border"
                  @click="searchModules"
                >
                  <i class="far fa-search" />
                </button>
              </template>
            </BasicInput>
          </div>
          <div class="card-body overflow-hidden">
            <div
              v-if="modules && modules.length > 0"
              class="row scroll modules"
            >
              <transition-group name="slide-fade">
                <div
                  v-for="item in modules"
                  :key="`text-module ${item.name}`"
                  class="col-12 mb-4 w-100"
                >
                  <TextModule
                    :name="item.name"
                    :title="item.symbol ? item.symbol + ' ' + item.title : item.title"
                    :text="item.text"
                    :short-text="true"
                    @add="addModule(item._id)"
                    @edit="editModule(item._id)"
                  />
                </div>
              </transition-group>
              <div
                v-if="search.length <= 0 && modules.length < total"
                class="d-flex justify-content-center w-100 py-2"
              >
                <button
                  class="btn bg-transparent text-primary"
                  @click="loadMoreModules"
                >
                  Mehr anzeigen...
                </button>
              </div>
            </div>
            <div
              v-else
              class="d-flex flex-column align-items-center"
            >
              <i class="fad fa-th-large fa-4x mb-3 text-primary" />
              <h5 class="mb-3">
                Es wurden noch keine Bausteine angelegt
              </h5>
              <button
                class="btn btn-primary"
                @click="showAddModal = true"
              >
                Baustein anlegen
              </button>
            </div>
          </div>
        </ca-card>
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header.vue'
import CaCard from '@/components/Card.vue'
import TextModule from './components/TextModule.vue'
import feathers from '../../api'
import PdfPreview from '@/components/PdfPreview.vue'
import AddTextModuleModal from '@/components/Modals/AddTextModuleModal.vue'
import EditTextModuleModal from '@/components/Modals/EditTextModuleModal.vue'
import axios from 'axios'
import BasicSelect from '@/components/BaseComponents/BasicSelect.vue'
import pdfTemplateTypes from '@/resources/enums/pdfTemplateTypes.js'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import SaveAgbFromEditor from '@/components/Modals/SaveAgbFromEditor.vue'
import moment from 'moment'
import formatDate from '@/filters/formatDate.js'
import agbStatus from '@/resources/enums/agbStatus.js'

export default {
  components: {
    CaHeader,
    CaCard,
    TextModule,
    PdfPreview,
    AddTextModuleModal,
    EditTextModuleModal,
    BasicSelect,
    BasicInput,
    SaveAgbFromEditor
    // PdfToolbar
  },
  data () {
    return {
      modules: [],
      agb: null,
      log: null,
      file: null,
      logList: null,
      previewData: null,
      previewMode: false,
      previewLoading: false,
      search: '',
      total: 0,
      showAddModal: false,
      showEditModal: false,
      showSaveModal: false,
      editId: null,
      savePending: true,
      saveSuccess: false,
      loading: false,
      legacyMode: false,
      logElementsLoaded: 0,
      moduleElementsLoaded: 0,
      pdfTemplateTypes,
      formatDate,
      agbStatus,
      uploadPending: false,
      attachments: [],
      tabIndex: 1
    }
  },
  computed: {
    humanStatusOptions () {
      return Object.values(this.agbStatus).map(status => ({ label: status.human, value: status.api }))
    },
    getScale () {
      if (window.innerWidth > 2150) {
        return 1.5
      } else if (window.innerWidth > 1750) {
        return 2.25
      } else if (window.innerWidth > 1150) {
        return 2.5
      } else {
        return 3
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.fetchData()
      }
    },
    search: {
      handler () {
        this.searchModules()
      }
    },
    tabIndex: {
      handler () {
        if (this.tabIndex === 0) {
          this.previewMode = false
        } else if (this.tabIndex === 1) {
          this.previewLoading = true
          this.previewMode = true
          setTimeout(() => {
            this.previewLoading = false
          }, 500)
        }
      }
    },
    showAddModal: {
      async handler () {
        if (this.showAddModal === false) {
          await this.refreshModules()
        }
      }
    },
    showEditModal: {
      async handler () {
        if (this.showEditModal === false) {
          await this.refreshModules()
        }
      }
    }
  },
  methods: {
    async fetchData () {
      this.loading = true
      try {
        // Lädt Bausteine
        await this.refreshModules()
        // Lädt Agb
        if (this.$route.params.agbId) {
          this.agb = await this.$store.dispatch('agbs/get', this.$route.params.agbId)
          // Lädt Verlauf
          const loglist = await this.$store.dispatch('agb-log/find', {
            query: {
              agbId: this.agb._id,
              $sort: {
                createdAt: -1
              }
            }
          })
          this.logList = loglist.data.reverse()
          this.logElementsLoaded = loglist.data.length
        } else {
          this.agb = new this.$FeathersVuex.api.Agb()
        }
        // Lädt Attachments
        if (this.agb.doc.attachmentIds && this.agb.doc.attachmentIds.length > 0) {
          this.attachments = (await this.$store.dispatch('file/find', {
            query: {
              _id: this.agb.doc.attachmentIds
            }
          })).data
        }
        // Erstellt neuen Log Eintrag
        this.log = new this.$FeathersVuex.api.AgbLog()
      } catch (e) {
        console.error(e)
      } finally {
        if (this.modules) {
          this.loading = false
        }
        if (!this.agb.doc.template && this.$route.params.agbId) {
          this.legacyMode = true
          this.reloadPreview()
        } else {
          this.agb.doc.template = pdfTemplateTypes.AGB.api
        }
        if (this.agb.doc.modules) {
          this.reloadPreview()
        }
      }
    },
    async loadMoreLogs () {
      try {
        const loglist = (await this.$store.dispatch('agb-log/find', {
          query: {
            agbId: this.agb._id,
            $skip: this.logElementsLoaded,
            $limit: 10,
            $sort: {
              createdAt: -1
            }
          }
        }))
        this.logList.unshift(...(loglist.data.reverse()))
        this.elementsLoaded += loglist.data.length
      } catch (error) {
        console.error(error)
      }
    },
    async loadMoreModules () {
      try {
        const modules = (await this.$store.dispatch('agb-module/find', {
          query: {
            $skip: this.moduleElementsLoaded,
            $limit: 25
          }
        }))
        this.modules.push(...(modules.data))
        this.total = modules.total
        this.moduleElementsLoaded += modules.data.length
      } catch (error) {
        console.error(error)
      }
    },
    async addModule (id) {
      const index = this.modules.findIndex(el => el._id === id)
      this.agb.doc.modules.push(this.modules[index])
      this.$forceUpdate()
      this.reloadPreview()
    },
    editModule (id) {
      this.editId = id
      this.showEditModal = true
      this.reloadPreview()
    },
    deleteModule (index) {
      this.agb.doc.modules.splice(index, 1)
      this.reloadPreview()
    },
    async reloadPreview () {
      try {
        this.previewLoading = true
        this.previewData = (await feathers.service('pdfs').create(this.agb)).buffer
      } catch (e) {
        console.error(e)
      } finally {
        if (this.previewData) {
          this.previewLoading = false
        }
      }
    },
    moveModule (index, newIndex) {
      if (index === 0 && newIndex <= 0) {
        return
      }
      this.agb.doc.modules.splice(newIndex, 0, this.agb.doc.modules[index])
      if (index < newIndex) {
        this.agb.doc.modules.splice(index, 1)
      } else {
        this.agb.doc.modules.splice(index + 1, 1)
      }
      this.reloadPreview()
    },
    setRate (rate) {
      console.log(rate)
    },
    async searchModules () {
      if (this.search.length > 0) {
        this.modules = (await this.$store.dispatch('agb-module/find', {
          query: {
            $search: this.search
          }
        })).data
      } else {
        this.modules = (await this.$store.dispatch('agb-module/find')).data
      }
    },
    async save () {
      try {
        this.savePending = true
        // Finale PDF erzeugen
        await this.reloadPreview
        // PDF auf AWS hochladen
        const file = await feathers.service('file').create({
          name: this.agb.name + '.pdf',
          mimeType: 'application/pdf'
        })
        await axios.put(file.uploadUrl, this.previewData, this.config)
        // AGB und Log speichern
        this.agb.fileId = file._id
        const savedAgb = await this.agb.save()
        this.log.agbId = savedAgb._id
        this.log.userId = this.$store.getters['auth/user'].user._id
        this.log.dateTime = moment()
        this.log.save()
        this.saveSuccess = true
      } catch (e) {
        console.error(e)
      } finally {
        if (this.saveSuccess) {
          this.$router.push({ name: 'AgbList' })
        }
        this.savePending = false
      }
    },
    async addPdfFile (evt) {
      try {
        this.uploadPending = true
        if (!this.agb.doc.attachmentIds) {
          this.agb.doc.attachmentIds = []
        }
        const file = await feathers.service('file').create({
          name: evt.target.files[0].name,
          mimeType: 'application/pdf'
        })
        await axios.put(file.uploadUrl, evt.target.files[0], this.config)
        this.agb.doc.attachmentIds.push(file._id)
        this.attachments.push(file)
      } catch (e) {
        console.error(e)
      } finally {
        this.uploadPending = false
        this.reloadPreview()
      }
    },
    removePdfFile (index) {
      try {
        this.attachments.splice(index, 1)
        this.agb.doc.attachmentIds.splice(index, 1)
      } catch (e) {
        console.error(e)
      } finally {
        this.reloadPreview()
      }
    },
    async refreshModules () {
      try {
        const modules = await this.$store.dispatch('agb-module/find')
        this.total = modules.total
        this.modules = modules.data
        this.moduleElementsLoaded = this.modules.length
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

  .space-header {
    margin-top: -67px;
    padding-top: 91px;
  }

  .card-header {
    border-bottom-color: #dee2e6;
  }

  .card-header.custom-header {
    min-height: 65px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tabs.standalone ::v-deep{
    &.card-header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: white;
      z-index: 1000;
    }
    &.tab-content {
      height: 100%;
      padding-top: 4rem;
      overflow: hidden;
    }
    &.tab-pane {
      height: 100%;
      overflow: hidden;
    }
    &.tab-pane>.card-body {
      height: 100%;
      overflow-y: scroll;
    }
    &.tab-pane>div {
      height: 100%;
    }
  }

  .scroll {
    max-height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar-track
    {
        border-radius: 10px;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar
    {
        width: 7px;
        height: 7px;
        background-color: #F5F5F5;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        background-color: $primary-dark;
    }
  }

  .col-9 {
    flex-grow: 1;
    transition: all 400ms ease;
  }

  .col-6 {
    flex-shrink: 1;
    transition: all 400ms ease;
  }

  .modules>span {
    width: 100%;
  }
</style>
