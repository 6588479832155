<template>
  <basic-form-group
    :id="id"
    :label-is-up="labelIsUp"
    :label="$attrs.label"
    :required="$attrs.required"
    :horizontal="$attrs.horizontal"
    :columns="$attrs.columns"
    :margin="$attrs.margin"
    :small="$attrs.small"
    :infotext="$attrs.infotext"
    :align-label-center="$attrs.alignLabelCenter"
  >
    <template slot="label">
      <slot name="label" />
    </template>
    <template slot="slotinfotext">
      <slot name="slotinfotext" />
    </template>
    <template #small>
      <slot name="small" />
    </template>
    <template slot="input">
      <div class="input-group">
        <div
          v-if="$scopedSlots.prepend"
          class="input-group-prepend"
        >
          <slot name="prepend" />
        </div>
        <input
          :id="id"
          v-model="model"
          v-validate="getValidation"
          :class="{'is-invalid': veeFields[veeField] && veeFields[veeField].validated && veeFields[veeField].invalid, 'disable-arrows': disableArrows}"
          :name="veeField"
          :data-vv-as="name"
          v-bind="$attrs"
          class="form-control"
          v-on="listeners"
        >
        <div
          v-if="$scopedSlots.append"
          class="input-group-append"
        >
          <slot name="append" />
        </div>
      </div>
      <validation-feedback :name="veeField" />
    </template>
  </basic-form-group>
</template>

<script>
import basicComponentMixin from './basic-component-mixin.js'
export default {
  name: 'BasicInput',
  mixins: [basicComponentMixin],
  props: {
    disableArrows: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="scss">

/* Chrome, Safari, Edge, Opera */
.disable-arrows::-webkit-outer-spin-button,
.disable-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.disable-arrows[type=number] {
  -moz-appearance: textfield;
}
</style>
