<template>
  <Modal
    v-if="show"
    size="lg"
    title="Baustein bearbeiten"
    :closable="false"
    @close="$emit('close')"
  >
    <template #body>
      <CaAlert
        v-if="showFormInvalidHint"
        variant="danger"
        class="mb-2"
        message="Es sind noch nicht alle erforderlichen Felder ausgefüllt"
        @close="showFormInvalidHint = null"
      />
      <div
        v-if="agbsUses && agbsUses.data.length > 0"
        class="bg-warning rounded w-100 p-3 mb-3"
        style="max-height: 200px"
      >
        <h5>
          <i class="fas fa-exclamation-triangle mr-3" />Dieser Baustein wird in folgenden Dokumenten verwendet:
        </h5>
        <ul class="m-0 testul">
          <li
            v-for="(doc, index) in agbsUses.data"
            :key="index"
          >
            {{ doc.name }}
          </li>
        </ul>
      </div>
      <BasicInput
        v-model="agbModule.name"
        :columns="{label: ['col'], input:['col-12']}"
        placeholder="Name"
        :horizontal="true"
        :margin="false"
        class="mb-3"
        label="Name des Bausteins"
        required
      />
      <label class="mb-0">Titel</label>
      <div class="form-row mb-3">
        <div class="col-12 col-md-3">
          <BasicInput
            v-model="agbModule.symbol"
            :columns="{label: ['d-none'], input:['col-12']}"
            placeholder="Symbol (z.B §1; a; 1)"
            :horizontal="true"
            :margin="false"
          />
        </div>
        <div class="col-12 col-md-9">
          <BasicInput
            v-model="agbModule.title"
            :columns="{label: ['d-none'], input:['col-12']}"
            placeholder="Überschrift des Bausteins"
            label="Überschrift des Bausteins"
            :horizontal="true"
            :margin="false"
            required
          />
        </div>
      </div>

      <label class="mb-0">Inhalt</label>
      <BasicWysiwyg
        v-model="agbModule.text"
        :file-upload="false"
        :large="true"
        :class="{'mb-3': !formCheck}"
      />
      <div
        v-if="formCheck"
        class="d-flex align-items-center"
      >
        <BasicCheckbox
          v-model="onlyThisDoc"
          class="mr-2"
        /> Baustein nur für dieses Formular bearbeiten (Es wird dadurch ein neuer Baustein erstellt)
      </div>
      <BasicInput
        v-model="agbModuleLog.text"
        :columns="{label: ['col'], input:['col-12']}"
        placeholder="Kommentar"
        :horizontal="true"
        :margin="false"
        label="Kommentar zur Änderung"
        required
      />
    </template>
    <template
      #footer
    >
      <button
        class="btn btn-primary float-right ml-2"
        @click="save()"
      >
        <i
          v-if="loading"
          class="far fa-spin fa-circle-notch"
        /> Speichern
      </button>
      <button
        class="btn hover-gray-2 text-primary float-right"
        @click="$emit('close')"
      >
        Abbrechen
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import BasicWysiwyg from '@/components/BaseComponents/BasicWysiwyg.vue'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox.vue'
import moment from 'moment'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    Modal,
    BasicInput,
    BasicWysiwyg,
    BasicCheckbox
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    editId: {
      type: String,
      default: ''
    },
    formCheck: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      agbModule: null,
      agbsUses: null,
      loading: false,
      agbModuleLog: null,
      savePending: false,
      showFormInvalidHint: false,
      onlyThisDoc: false
    }
  },
  watch: {
    show: {
      immediate: true,
      handler () {
        if (this.editId) {
          this.fetchData()
          this.showFormInvalidHint = false
        }
      }
    }
  },
  methods: {
    async fetchData () {
      try {
        this.loading = true
        this.agbModule = await this.$store.dispatch('agb-module/get', this.editId)
        this.agbsUses = await this.$store.dispatch('agbs/find', {
          query: {
            'doc.modules._id': this.agbModule._id
          }
        })
        this.agbModuleLog = new this.$FeathersVuex.api.AgbModuleLog()
        this.$refs.editor.setHTML()
      } catch (e) {
        console.error(e)
      } finally {
        if (this.agbModule) {
          this.loading = false
        }
      }
    },
    async save () {
      const valid = await this.$validator.validateAll() && this.agbModule.text
      try {
        if (this.savePending) {
          return
        } else if (!valid) {
          this.showFormInvalidHint = true
          return
        }
        this.savePending = true
        this.loading = true
        let savedModule
        if (this.onlyThisDoc) {
          delete this.agbModule._id
          savedModule = await this.$store.dispatch('agb-module/create', this.agbModule)
        } else {
          savedModule = await this.agbModule.save()
        }
        this.agbModuleLog.moduleId = savedModule._id
        this.agbModuleLog.userId = this.$store.getters['auth/user'].user._id
        this.agbModuleLog.dateTime = moment()
        this.agbModuleLog.save()
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
        if (valid) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  ul {
    column-count: 4;
    column-gap: 10px;
  }
</style>
