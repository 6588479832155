<template>
  <div class="pdf-wrapper">
    <embed
      :src="pdfBlob"
      type="application/pdf"
    >
  </div>
</template>

<script>
export default {
  components: {
    // PdfPage
  },
  props: {
    data: {
      type: [ArrayBuffer, String],
      required: true
    }
  },
  computed: {
    pdfBlob () {
      const blob = new Blob([this.data], { type: 'application/pdf' })
      return URL.createObjectURL(blob)
    }
  }
}
</script>

<style lang="scss" scoped>
  .pdf-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  embed {
    width: 100%;
    height: 100%;
  }
</style>
