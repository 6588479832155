<template>
  <transition name="modal">
    <div
      class="modal-mask d-flex align-items-center justify-content-center"
      @click.self="close()"
    >
      <div
        class="mx-auto modal-container"
        :class="[{'sm': size === 'sm'}, {'lg': size === 'lg'}]"
      >
        <card :class="{'h-100': maxHeight}">
          <template
            v-if="$scopedSlots.header || title"
            #header
          >
            <slot name="header">
              <div class="form-row">
                <div class="col">
                  <h4 class="mb-0">
                    {{ title }}
                  </h4>
                </div>
                <div
                  v-if="closable"
                  class="col-auto"
                >
                  <button
                    class="btn btn-link text-gray-4 p-0"
                    @click="close()"
                  >
                    <i class="fas fa-times" />
                  </button>
                </div>
              </div>
            </slot>
          </template>

          <template
            v-if="$scopedSlots.body"
            #body
          >
            <slot name="body" />
          </template>

          <template
            v-if="$scopedSlots.footer"
            #footer
          >
            <slot name="footer" />
          </template>
        </card>
      </div>
    </div>
  </transition>
</template>

<script>
import Card from './Card'
export default {
  name: 'Modal',
  components: {
    Card
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    closable: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'md'
    },
    maxHeight: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close () {
      if (this.closable) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 1093;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s ease;
}

.modal-container {
  width: 600px;
  &.lg{
    width: 900px;
    height: 75%;
    overflow: auto;
  }
  &.sm{
    width: 300px;
  }
  transition: all .3s ease;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
