<template>
  <basic-form-group
    :id="id"
    :label-is-up="labelIsUp"
    :label="$attrs.label"
    :required="$attrs.required"
    :horizontal="$attrs.horizontal"
    :columns="$attrs.columns"
    :margin="$attrs.margin"
    :small="$attrs.small"
    :infotext="$attrs.infotext"
    :align-label-center="$attrs.alignLabelCenter"
  >
    <template slot="label">
      <slot name="label" />
    </template>
    <template slot="slotinfotext">
      <slot name="slotinfotext" />
    </template>
    <template slot="input">
      <div class="input-group">
        <div
          v-if="$scopedSlots.prepend"
          class="input-group-prepend"
        >
          <slot name="prepend" />
        </div>
        <select
          :id="id"
          v-model="model"
          v-validate="getValidation"
          :class="{'is-invalid': veeFields[veeField] && veeFields[veeField].validated && veeFields[veeField].invalid}"
          :name="veeField"
          :data-vv-as="name"
          v-bind="$attrs"
          class="custom-select"
        >
          <option
            v-if="$attrs.placeholder"
            :value="undefined"
            disabled
            hidden
          >
            {{ $attrs.placeholder }}
          </option>
          <slot>
            <template
              v-for="option of options"
            >
              <option
                v-if="option.visible == undefined || option.visible === true"
                :key="option.id || option.value"
                :disabled="option.disabled"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </template>
          </slot>
        </select>
        <div
          v-if="$scopedSlots.append"
          class="input-group-append"
        >
          <slot name="append" />
        </div>
      </div>
      <validation-feedback :name="veeField" />
    </template>
  </basic-form-group>
</template>

<script>
import basicComponentMixin from './basic-component-mixin.js'
export default {
  name: 'BasicSelect',
  mixins: [basicComponentMixin],
  props: {
    options: {
      type: Array,
      required: false,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

/* For IE10 */
select::-ms-expand {
  display: none;
}
</style>
