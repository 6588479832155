<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="card module"
    @mouseenter="showHint = true"
    @mouseleave="showHint = false"
  >
    <div class="card-header bg-white d-flex justify-content-between align-items-center">
      <h5 class="m-0">
        {{ name || 'Baustein' }}
      </h5>
      <div
        v-if="disableHover"
        class="control"
      >
        <button
          class="btn btn-primary px-2 py-1 mx-1"
          @click="$emit('up')"
        >
          <i class="fas fa-chevron-up" />
        </button>
        <button
          class="btn btn-primary px-2 py-1 mx-1"
          @click="$emit('down')"
        >
          <i class="fas fa-chevron-down" />
        </button>
        <button
          class="btn btn-primary px-2 py-1 mx-1"
          @click="$emit('delete')"
        >
          <i class="fas fa-trash" />
        </button>
      </div>
    </div>
    <div
      class="card-body"
      :class="{'padding': disableHover}"
    >
      <!-- <div
        v-if="disableHover"
        class="position-absolute right-0 top-0 card-body control"
      >
        <button
          class="btn btn-primary d-block px-2 py-1 mb-2"
          @click="$emit('up')"
        >
          <i class="fas fa-chevron-up" />
        </button>
        <button
          class="btn btn-primary d-block px-2 py-1 mb-2"
          @click="$emit('down')"
        >
          <i class="fas fa-chevron-down" />
        </button>
        <button
          class="btn btn-primary d-block px-2 py-1"
          @click="$emit('delete')"
        >
          <i class="fas fa-trash" />
        </button>
      </div> -->
      <h5>{{ title }}</h5>
      <p
        v-if="!shortText"
        class="m-0"
        v-html="text"
      />
      <p
        v-else
        class="m-0"
        v-html="shortenText"
      />
    </div>
    <transition name="fade">
      <div
        v-if="showHint && !disableHover"
        class="add-hint"
      >
        <template v-if="showAdd">
          <div
            title="Zum Dokument hinzufügen"
            class="m-2 p-2 rounded custom-button"
            @click="$emit('add')"
          >
            <i class="fal fa-plus-circle fa-3x text-primary" />
          </div>
          <div class="divider py-4" />
        </template>
        <div
          title="Baustein bearbeiten"
          class="m-2 p-2 rounded custom-button"
          @click="$emit('edit')"
        >
          <i class="fal fa-edit fa-3x text-primary" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'TextModule',
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    shortText: {
      type: Boolean,
      default: false
    },
    disableHover: {
      type: Boolean,
      default: false
    },
    showAdd: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showHint: false,
      controlHover: false
    }
  },
  computed: {
    shortenText () {
      return this.text.slice(0, 275) + '...'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
  .module {
    width: 100%;
    & .card-body {
      min-height: 203px;
    }
  }

  .add-hint {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: $info, $alpha: 0.1);
    backdrop-filter: blur(5px);
    border-radius: 8px;
  }

  .control {
    opacity: 0.25;
    transition: .2s;
  }

  .control:hover{
    opacity: 1;
  }

  .padding {
    padding-top: 4.5em;
  }

  .custom-button {
    cursor: pointer;
    transition: .2s;
  }

  .custom-button:hover {
    background-color: rgba($color: #FFF, $alpha: 0.7);
  }
</style>
