<template>
  <basic-form-group
    :id="id"
    :label-is-up="labelIsUp"
    :required="$attrs.required"
    :horizontal="$attrs.horizontal"
    :columns="$attrs.columns"
    :margin="$attrs.margin"
    :small="$attrs.small"
    :infotext="$attrs.infotext"
    :align-label-center="$attrs.alignLabelCenter"
  >
    <template
      v-if="$attrs.horizontal"
      slot="label"
    >
      <slot name="label" />
    </template>
    <template slot="input">
      <div class="custom-control custom-checkbox">
        <input
          :id="id"
          v-model="model"
          v-validate="getValidation"
          :class="{'is-invalid': veeFields[veeField] && veeFields[veeField].validated && veeFields[veeField].invalid}"
          :name="veeField"
          :data-vv-as="name"
          v-bind="$attrs"
          type="checkbox"
          class="custom-control-input"
        >
        <label
          :for="id"
          class="custom-control-label"
        >
          {{ $attrs.label }}
          <slot name="label" />
          <span
            v-if="$attrs.required !== undefined && $attrs.required !== false"
            class="required"
          > *</span>
          <span
            v-if="infotext || $scopedSlots.slotinfotext"
            class="d-inline-block"
          >
            <i
              :id="id+'infotext'"
              class="far fa-info-circle text-gray-4 ml-2"
            />
            <b-popover
              :target="id+'infotext'"
              placement="top"
              triggers="hover focus"
              :content="infotext"
            >
              <slot name="slotinfotext" />
            </b-popover>
          </span>
        </label>
      </div>
      <validation-feedback :name="veeField" />
    </template>
  </basic-form-group>
</template>

<script>
import basicComponentMixin from './basic-component-mixin.js'
export default {
  name: 'BasicCheckbox',
  mixins: [basicComponentMixin],
  props: {
    infotext: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>

span.required {
  color: #9e0000;
}

</style>
