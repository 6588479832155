<template>
  <transition name="shake">
    <div
      v-show="errors.has(name)"
      class="text-danger"
    >
      {{ customErrorMessage ? customErrorMessage : errors.first(name) }}
    </div>
  </transition>
</template>

<script>
export default {
  inject: ['$validator'],
  name: 'ValidationFeedback',
  props: {
    name: {
      type: String,
      required: true
    },
    customErrorMessage: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  .shake-enter-active {
    animation: shake 0.5s;
  }

  @keyframes shake {
    8%, 41% {
      -webkit-transform: translateX(-10px);
    }

    25%, 58% {
      -webkit-transform: translateX(10px);
    }

    75% {
      -webkit-transform: translateX(-5px);
    }

    92% {
      -webkit-transform: translateX(5px);
    }

    0%, 100% {
      -webkit-transform: translateX(0);
    }
  }
</style>
